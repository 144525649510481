<template>
<div class="p-16">
    <VueSlickCarousel v-bind="setting">
        <div><img class="mr-10 h-40 border py-10 px-10 rounded" src="../../../assets/img/programming_languages/react-js-icon.png" alt="react"></div>
        <div><img class="mr-10 h-40 border py-12 px-10 rounded" src="../../../assets/img/programming_languages/Nextjs-logo-icon.png" alt="next"></div>
        <div><img class="mr-10 h-40 border py-5 px-10 rounded" src="../../../assets/img/programming_languages/laravel-icon.png" alt="laravel"></div>
        <div><img class="mr-10 h-40 border py-10 px-10 rounded" src="../../../assets/img/programming_languages/vue.png" alt="vue"></div>
        <div><img class="mr-10 h-40 border py-2 px-10 rounded" src="../../../assets/img/programming_languages/nodejs-logo.svg" alt="nodejs"></div>
        <div><img class="mr-10 h-40 border py-8 px-10 rounded" src="../../../assets/img/programming_languages/Expressjs.png" alt="express"></div>
        <div><img class="mr-10 h-40 border py-8 px-10 rounded" src="../../../assets/img/programming_languages/mysql-icon.png" alt="mysql"></div>
        <div><img class="mr-10 h-40 border py-8 px-10 rounded" src="../../../assets/img/programming_languages/postgresql-icon.png" alt="postgresql"></div>
    </VueSlickCarousel>
</div>
</template>

<script>
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'
export default {
    name: "ProgrammingLanguagesIconSection",
    data: () => {
        return {
            setting: {
                "dots": false,
                "focusOnSelect": true,
                "infinite": true,
                "speed": 500,
                "slidesToShow": 1,
                "slidesToScroll": 1,
                "touchThreshold": 1,
                "variableWidth": true,
                "autoplay": true,
                "centerMode": true,
                "centerPadding": "20px",
            }
        }
    },
    components: {
        VueSlickCarousel
    }
}
</script>

<style scoped>

</style>
