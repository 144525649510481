<template>
  <div class="w-full grid grid-cols-6 mt-14 mb-14 px-0 md:px-32">
    <div class="col-span-6 md:col-span-2 mb-20 mg:mb-0">
      <div class="w-full flex justify-center">
        <img src="../../../assets/img/projects/android/android_york.png" style="max-width: 250px" alt="laravel">
      </div>
      <div class="flex justify-center flex-wrap">
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">Android</p>
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">Java</p>
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">Firebase</p>
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">Chat Robot</p>
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">Sport Coach Matching</p>
      </div>
    </div>
    <div class="col-span-6 md:col-span-2 mb-20 mg:mb-0">
      <div class="w-full flex justify-center">
        <img src="../../../assets/img/projects/android/android_pinshift.png" style="max-width: 250px" alt="laravel">
      </div>
      <div class="flex justify-center flex-wrap">
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">Android</p>
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">Java</p>
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">REST API</p>
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">Stripe</p>
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">HR System</p>
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">Face Recognition</p>
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">Internet of Things</p>
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">Web Socket</p>
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">Github</p>
      </div>
    </div>
    <div class="col-span-6 md:col-span-2 mb-20 mg:mb-0">
      <div class="w-full flex justify-center">
        <img src="../../../assets/img/projects/android/android_hrconnect.png" style="max-width: 250px" alt="laravel">
      </div>
      <div class="flex justify-center flex-wrap">
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">Android</p>
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">Java</p>
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">REST API</p>
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">Rostering System</p>
        <p class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">Github</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProjectAndroid",
}
</script>

<style scoped>
.transition{
  transition: 1s;
}
</style>