<template>
  <div class="w-full h-full">
    <div class="h-full px-5 md:px-10 lg:px-20 py-10 bg-gray-900">
      <div class="grid grid-cols-1 md:grid-cols-3 text-white">
        <div class="text-left mb-10 md:mb-0">
          <p class="px-12 pb-2 lg:pb-5 subpixel-antialiased font-extrabold tracking-widest leading-loose text-left text-xl">About</p>
          <p class="px-12 pb-2 subpixel-antialiased font-bold tracking-widest leading-loose text-sm text-left break-wordss">
            Brian Tam
          </p>
          <p class="px-12 pb-2 subpixel-antialiased font-bold tracking-widest leading-loose text-sm text-left break-words">
            Web & Application Developer
          </p>
        </div>
        <div class="text-left mb-10 md:mb-0">
          <p class="px-12 pb-2 lg:pb-5 subpixel-antialiased font-extrabold tracking-widest leading-loose text-left text-xl">Sections</p>
          <a class="px-12 pb-2 subpixel-antialiased font-bold tracking-widest leading-loose text-sm text-left  block break-words" href="#" v-scroll-to="home_scroll">
            Home
          </a>
          <a class="px-12 pb-2 subpixel-antialiased font-bold tracking-widest leading-loose text-sm text-left  block break-words" href="#" v-scroll-to="about_me_scroll">
            About Me
          </a>
          <a class="px-12 pb-2 subpixel-antialiased font-bold tracking-widest leading-loose text-sm text-left  block break-words" href="#" v-scroll-to="projects_android_scroll">
            Android Application Projects
          </a>
          <a class="px-12 pb-2 subpixel-antialiased font-bold tracking-widest leading-loose text-sm text-left  block break-words" href="#" v-scroll-to="projects_web_scroll">
            Web Application Projects
          </a>
        </div>
        <div class="text-left mb-10 md:mb-0">
          <p class="px-12 pb-2 lg:pb-5 subpixel-antialiased font-extrabold tracking-widest leading-loose text-left text-xl">Contact</p>
          <p class="px-12 pb-2 subpixel-antialiased font-bold tracking-widest leading-loose text-sm text-left break-words">
            brian.tam.company@gmail.com
          </p>
          <p class="px-12 pb-2 subpixel-antialiased font-bold tracking-widest leading-loose text-sm text-left break-words">
            +852 5378 9954
          </p>
          <div class="flex flex-wrap justify-start px-12 pt-2">
            <a href="https://github.com/Briantam0422" target="_blank"><i class="fa-brands fa-github text-2xl mr-5 hover-pointer"></i></a>
            <a href="https://www.linkedin.com/in/heung-wing-tam-a421091b7/" target="_blank"><i class="fa-brands fa-linkedin text-2xl mr-5 hover-pointer"></i></a>
          </div>
        </div>
      </div>
      <div class="pt-10 text-gray-400">
        <p class="text-sm md:text-md break-words">All rights reserved by Brian TAM HEUNG WING <span>&copy;</span> 2022.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FooterVue",
  data(){
    return{
      home_scroll : {
        el : '#home',
        offset : 0,
        cancelable : true
      },
      about_me_scroll : {
        el : '#about-me',
        offset : 0,
        cancelable : true
      },
      projects_android_scroll : {
        el : '#projects-android',
        offset : -20,
        cancelable : true
      },
      projects_web_scroll : {
        el : '#projects-web',
        offset : -20,
        cancelable : true
      },
    }
  },
}
</script>

<style scoped>

</style>