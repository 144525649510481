<template>
  <div class="home">
    <welcome-section></welcome-section>
    <about-me-section></about-me-section>
    <programming-languages-icon-section></programming-languages-icon-section>
    <project-main></project-main>
  </div>
</template>

<script>
import WelcomeSection from "@/components/home/welcome/WelcomeSection";
import AboutMeSection from "@/components/home/about_me/AboutMeSection";
import ProgrammingLanguagesIconSection from "@/components/home/programming_languages_icon/ProgrammingLanguagesIconSection";
import ProjectMain from "@/components/home/projects/ProjectMain";

export default {
  name: 'HomeView',
  components: {
    WelcomeSection,
    AboutMeSection,
    ProgrammingLanguagesIconSection,
    ProjectMain
  }
}
</script>

<style>
  .home{
    width: 100%;
    height: 100%;
  }
</style>