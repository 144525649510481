<template>
  <div class="flex flex-cols pt-2 pb-2">
    <a :class="['mr-3', 'px-14', 'py-2', bg_color,'hover:bg-white', border, border_color,'rounded-full', 'text-white', 'hover:text-blue-900', 'font-extrabold', 'tracking-widest']" v-scroll-to="projects_scroll">
      {{ text }}
    </a>
  </div>
</template>

<script>
export default {
  name: "ButtonWithBg",
  props: ['bg_color','hover_bg_color', 'text','hover_text_color','border', 'border_color'],
  data(){
    return{
      projects_scroll : {
        el : '#projects',
        offset : 0,
        cancelable : true
      },
    }
  },
}
</script>

<style scoped>
:hover{
  transition: 0.8s;
}
</style>