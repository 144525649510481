import { render, staticRenderFns } from "./ProgrammingLanguagesIconSection.vue?vue&type=template&id=889fc066&scoped=true"
import script from "./ProgrammingLanguagesIconSection.vue?vue&type=script&lang=js"
export * from "./ProgrammingLanguagesIconSection.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "889fc066",
  null
  
)

export default component.exports