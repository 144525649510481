<template>
  <div class="w-full grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 mt-14 mb-14 px-0 md:px-32">
    <div v-for="project in projects" :key="project.projectName">
      <project-web-item :projectName="project.projectName" :post="project.post" :tags="project.tags" :image="project.image" :imageAlt="project.imageAlt" :link="project.link"></project-web-item>
    </div>
  </div>
</template>

<script>
import ProjectWebItem from './ProjectWebItem.vue';
import PinShiftImage from '@/assets/img/projects/web/pinshift.jpg'
import HRConnectImage from '@/assets/img/projects/web/hrconnect.jpg'
import FireAlarmImage2 from '@/assets/img/projects/web/emsdfsd2.png'
import CtscbImage2 from '@/assets/img/projects/web/ctscb2.png'
import WhatToDo from '@/assets/img/projects/web/whatodo.jpg'
import TutorStation from '@/assets/img/projects/web/tutor_station.jpg'
import Portfolio2 from '@/assets/img/projects/web/porfolio.jpg'
import Portfolio1 from '@/assets/img/projects/web/fisrt_profile_page.jpg'
import DigitalSATImage from '@/assets/img/projects/web/digitalsat.png'
import Chatapp from '@/assets/img/projects/web/chatapp.png'
import ETSImage from '@/assets/img/projects/web/ets.png'
import XoocityImage from '@/assets/img/projects/web/xoocity.png'
import EventSync from '@/assets/img/projects/web/eventsync.png'
export default {
  name: "ProjectWeb",
  components: {
    ProjectWebItem
  },
  data: () => {
    return {
      projects: [
        {
          projectName: 'digitalsat.ai & deeptests.ai - An e-learning platform',
          post: "Full-stack Developer",
          image: DigitalSATImage,
          imageAlt: "digitalsat.ai and deeptests.ai",
          link: "https://digitalsat.ai",
          tags: ['Vue3', 'Pinia', 'Laravel', 'PHP', 'HTML', 'CSS', 'Javascript', 'Typescript', 'Tailwind CSS', 'Stripe', 'REST APIs', 'MySQL', 'AWS', 'Linux', 'Git', 'Github']
        },
        {
          projectName: 'Xoocity - A Web3 Crypto and NFT Application',
          post: "Frontend Developer (Freelancer)",
          image: XoocityImage,
          imageAlt: "xoocity",
          link: "https://www.xoocity.com/",
          tags: ['React', 'Nextjs', 'Prisma', 'Yii', 'PHP', 'HTML', 'CSS', 'Javascript', 'Typescript', 'Ant design UI', 'REST APIs', 'MySQL', 'Git', 'Github']
        },
        {
          projectName: 'PinShift - B2B SaaS HR System',
          post: "Full-stack Developer",
          image: PinShiftImage,
          imageAlt: "PinShift",
          link: "https://pinshiftapp.com/",
          tags: ['Vue2', 'Vuex', 'Laravel', 'PHP', 'HTML', 'CSS', 'Javascript', 'Typescript', 'Bootstrap', 'Tailwind CSS', 'Stripe', 'Web Socket', 'REST APIs', 'MySQL', 'AWS', 'Linux', 'Git', 'Github']
        },
        {
          projectName: 'HRConnect - B2B SaaS Timesheet Management System',
          post: "Full-stack Developer",
          image: HRConnectImage,
          imageAlt: "HRConnect",
          link: "https://gethrconnect.com/",
          tags: ['Vue3', 'Pinia', 'Laravel', 'PHP', 'HTML', 'CSS', 'Javascript', 'Typescript', 'Tailwind CSS', 'Stripe', 'Web Socket', 'REST APIs', 'MySQL', 'AWS', 'Linux', 'Git', 'Github']
        },
        {
          projectName: 'CMS - Construction Material Lab Management System',
          post: "Full-stack Developer",
          image: ETSImage,
          imageAlt: "ets",
          link: null,
          tags: ['Laravel', 'PHP', 'HTML', 'CSS', 'Javascript', 'Typescript', 'Tailwind CSS', 'REST APIs', 'MySQL', 'AWS', 'Linux', 'Git', 'Github']
        },
        {
          projectName: 'CMS - Data Visualization Dashboard',
          post: "Full-stack Developer",
          image: FireAlarmImage2,
          imageAlt: "fire-alarm-system",
          link: null,
          tags: ['Laravel', 'PHP', 'HTML', 'CSS', 'Javascript', 'Typescript', 'Tailwind CSS', 'REST APIs', 'MySQL', 'AWS', 'Linux', 'Git', 'Github']
        },
        {
          projectName: 'CMS - Administrative Management System',
          post: "Full-stack Developer",
          image: CtscbImage2,
          imageAlt: "ctscb",
          link: null,
          tags: ['Laravel', 'PHP', 'HTML', 'CSS', 'Javascript', 'Typescript', 'Tailwind CSS', 'REST APIs', 'MySQL', 'AWS', 'Linux', 'Git', 'Github']
        },
        {
          projectName: 'EventSync - Event Management Platform',
          post: "Full-stack developer - Chingu Project",
          image: EventSync,
          imageAlt: "eventsync",
          link: 'https://github.com/chingu-voyages/v48-tier3-team-24',
          tags: ['Next.js', 'T3', 'Prisma', 'Nodejs', 'HTML', 'CSS', 'Javascript', 'Typescript', 'Tailwind CSS', 'REST APIs', 'MySQL', 'Vercel', 'Git', 'Github']
        },
        {
          projectName: 'A Group Chat Application',
          post: "Side Project",
          image: Chatapp,
          imageAlt: "chatapp",
          link: 'https://github.com/Briantam0422/chatapp-frontend',
          tags: ['Golang', 'Vue3', 'HTML', 'CSS', 'Javascript', 'Typescript', 'Tailwind CSS', 'REST APIs', 'MySQL', 'Web Socket', 'AWS', 'Linux', 'Git', 'Github']
        },
        {
          projectName: 'Tutor Station',
          post: "Side Project",
          image: TutorStation,
          imageAlt: "tutor-station",
          link: 'https://briantam0422.github.io/tutor_web/',
          tags: ['HTML5', 'Javascript', 'CSS', 'Firebase', 'Git', 'Github']
        },
        {
          projectName: 'WhaTodo',
          post: "Side Project",
          image: WhatToDo,
          imageAlt: "whatTodo",
          link: null,
          tags: ['Vue.js', 'Express.js', 'TypeScript', 'REST APIs', 'MongoDB', 'Git', 'Github']
        },
        {
          projectName: 'Portfolio Version 2',
          post: "",
          image: Portfolio2,
          imageAlt: "Portfolio 2",
          link: 'https://briantambusiness.com',
          tags: ['Vue.js', 'Tailwind CSS', 'HTML', 'TypeScript', 'CSS', 'Git', 'Github', 'Netify']
        },
        {
          projectName: 'Portfolio Version 1',
          post: "",
          image: Portfolio1,
          imageAlt: "Portfolio 1",
          link: 'https://briantam0422.github.io',
          tags: ['HTML', 'Javascript', 'CSS', 'Git', 'Github']
        },
      ]
    }
  }
}
</script>