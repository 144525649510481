<template>
  <div class="h-full grid grid-rows-1 gap-1 content-center items-center px-5 md:px-10 pt-10">
<!--    <p class="pb-5 text-gray-400">\ Programming Languages \</p>-->
    <div>
      <div v-for="language in languages" :key="language.language" class="">
        <div :class="language.class">
          <div class="code">
            <p :class="['programming-language', language.direction, 'text-gray-400']">{{ language.language }}</p>
            <div class="code w-full bg-gray-800 bg-opacity-90 rounded shadow-2xl">
              <div class="code-content w-full p-6 text-left text-white">
                <div v-for="code in language.codes" :key="code">
                  <typewriter
                      :type-interval="50"
                  >
                    <code>{{ code }}</code>
                    <br>
                  </typewriter>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="text-gray-400 col-span-1">React.js \ Next.js \ Vue.js \ Bootstrap \ Tailwindcss \ Node.js \ Express.js ... </p>
    </div>
    
  </div>

</template>

<script>
import Typewriter from "typewriter-vue";
export default {
  name: "CodeSection",
  components:{
    Typewriter
  },
  data(){
    return {
      languages: [
        {
          language: "Javascript",
          codes: [
              'const message = "Welcome to my website";',
              "console.log(message);"
          ],
          direction: "text-left",
          class:['flex', 'justify-start', 'pb-10'],
        },
        {
          language: "PHP - Laravel",
          codes: [
              "$message = 'I love programming';",
              "Log::debug('message: ' . $message);"
          ],
          direction: "text-right",
          class:['flex', 'justify-end', 'pb-10'],
        },
        {
          language: "Typescript",
          codes: [
            "const message:string = 'Learn more about me';",
            "console.log(message);"
          ],
          direction: "text-left",
          class:['flex', 'justify-start', 'pb-10'],
        },
        {
          language: "Python",
          codes: [
            "message = 'Always keep my finger on the pulse'",
            "print(message)"
          ],
          direction: "text-right",
          class:['flex', 'justify-end', 'pb-0', "md:pb-10", 'hidden'],
        }
      ]
    }
  }
}
</script>

<style scoped>
.code{
  max-width: 550px;
  width: 100%;
  height: 100%;
}
</style>