<script>
export default {
    name: "ProjectWebItem",
    props: {
        tags: Array,
        image: String,
        imageAlt: String,
        link: String,
        projectName: String,
        post: String
    },
    setup(props) {
        console.log(props, 'adsf')
    },
    data : () => {
        return {}
    }
}
</script>

<template>
    <div class="h-full p-5 md:px-10">
        <div class="w-full shadow-lg rounded-xl flex flex-wrap">
          <div class=" w-full md:h-3/4 rounded-t-lg">
            <img class="w-full h-full rounded-t-lg" :src="image" :alt="imageAlt"/>
          </div>
          <div class="w-full h-1/4 rounded-b-lg text-left">
            <p class="px-6 md:px-10 pt-2 lg:pt-5 subpixel-antialiased font-extrabold tracking-widest leading-loose text-lg md:text-xl2">{{projectName}}</p>
            <p class="px-6 md:px-10 mb-2 subpixel-antialiased tracking-widest leading-loose text-md">{{post}}</p>
            <a v-if="link != null" :href="link" class="hover:text-blue-600" target="_blank">
              <p class="flex px-6 md:px-10 pb-2 subpixel-antialiased  tracking-widest leading-loose text-lg md:text-sm overflow-ellipsis overflow-hidden">
                <img src="../../../assets/img/icon/i-link.svg" class="w-3 mr-2" alt="icon-link"/>
                {{link}}
              </p>
            </a>
            <div class="flex justify-left flex-wrap px-6 md:px-8 pb-5 mt-2">
              <p v-for="tag in tags" :key="tag" class="my-1 mx-1 px-5 bg-gray-400 rounded-full hover:bg-gray-600 font-extrabold text-white transition">{{tag}}</p>
            </div>
          </div>
        </div>
      </div>
      
</template>