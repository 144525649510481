<template>
  <div id="projects" class="w-full h-full">
    <div class="text-center">
      <p class="px-12 pb-2 lg:pb-5 subpixel-antialiased font-extrabold tracking-widest leading-loose text-2xl">Projects</p>
      <p class="mx-12 md:mx-44 pb-2 lg:pb-10 subpixel-antialiased text-center font-bold tracking-widest leading-loose text-gray-600 text-sm max-h-60 overflow-y-auto">
        All the projects I have participated within recent two years are shown below. I am responsible for UX/UI design, database design, system design, frontend development, and backend development.
      </p>
    </div>

    <div id="projects-android"  class="w-full grid grid-cols-7 place-content-center flex items-center mt-10">
      <hr class="col-span-3 ml-12 mr-2 md:mr-0 md:ml-44">
      <div class="col-span-1 subpixel-antialiased font-extrabold tracking-widest text-gray-500">Android</div>
      <hr class="col-span-3 mr-12 ml-5 md:ml-0 md:mr-44">
    </div>

    <project-android></project-android>

    <div id="projects-web"  class="w-full grid grid-cols-7 place-content-center flex items-center mt-10">
      <hr class="col-span-3 ml-12 mr-2 md:mr-0 md:ml-44">
      <div class="col-span-1 subpixel-antialiased font-extrabold tracking-widest text-gray-500">Web</div>
      <hr class="col-span-3 mr-12 ml-5 md:ml-0 md:mr-44">

    </div>
    <project-web></project-web>
  </div>
</template>

<script>
import ProjectAndroid from "@/components/home/projects/ProjectAndroid";
import ProjectWeb from "@/components/home/projects/ProjectWeb";
export default {
  name: "ProjectMain",
  components: {
    ProjectAndroid,
    ProjectWeb
  }
}
</script>

<style scoped>

</style>