<template>
  <div id="about-me" class="about-me-section w-screen h-full flex flex-col md:flex-row pt-10 md:pt-0">
    <div class="left w-screen  flex flex-wrap flex-1 justify-start content-center pt-20 pb-20">
      <p class="px-12 pb-2 lg:pb-5 subpixel-antialiased font-extrabold tracking-widest leading-loose text-2xl ">About Me</p>
      <p class="px-12 pb-2 lg:pb-5 subpixel-antialiased font-bold tracking-widest leading-loose text-gray-600 text-sm text-justify pb-10">
        Hi, I am a web and application developer with 3 years of practical experience.
        Specializing in both front-end and back-end development, I bring expertise in HTML, CSS, Javascript, Typescript, React, Nextjs, Nodejs, Express.js, PHP, and Laravel.
      </p>
      <p class="px-12 pb-2 lg:pb-5 subpixel-antialiased font-bold tracking-widest leading-loose text-gray-600 text-sm text-justify pb-10">
        I'm passionate about transforming innovative ideas into impactful solutions. Whether it's crafting intuitive user interfaces or developing robust backend systems, I'm dedicated to creating products that make a difference.
      </p>
      <p class="px-12 pb-2 lg:pb-10 subpixel-antialiased font-bold tracking-widest leading-loose text-gray-600 text-sm text-justify pb-10">
        "Dream bigger and work harder" is the mantra that drives me to pursue my dreams and stay updated with state-of-the-art technologies. I'm committed to continuous learning and pushing the boundaries of what's possible in the ever-evolving field of web development.
        Let's collaborate and bring your digital visions to life!"
      </p>
      <div class="card w-full h-full flex flex-col justify-center bg-gray-900 text-white">
        <p class="subpixel-antialiased font-extrabold tracking-widest leading-loose text-2xl">Brian Tam</p>
        <p class="subpixel-antialiased font-normal tracking-widest leading-loose text-lg mb-2">brian.tam.dev@gmail.com</p>
        <div class="flex flex-wrap justify-center">
          <a href="https://github.com/Briantam0422" target="_blank"><i class="fa-brands fa-github text-2xl mr-5 hover-pointer"></i></a>
          <a href="https://www.linkedin.com/in/brian-tam-dev/" target="_blank"><i class="fa-brands fa-linkedin text-2xl mr-5 hover-pointer"></i></a>
        </div>
      </div>
      <div class="about-me-button w-full flex flex-wrap px-12 pt-5 pb-5 justify-center">
        <button-with-bg
          bg_color = "bg-blue-900"
          border = "border-2"
          border_color="border-blue-900"
          hover_bg_color = "bg-white"
          hover_text_color = "text-blue-900"
          text = "Projects"
        ></button-with-bg>
        <!-- <a :href="resume" download="Brian_Tam_Resume.pdf">
          <button-outline
              border = "border-2"
              border_color="border-blue-900"
              hover_bg_color = "bg-blue-900"
              text = "Download Resume"
              text_color = "text-blue-900"
              hover_text_color = "text-white"
          ></button-outline>
        </a> -->

      </div>
    </div>
    <div class="right flex-1">
      <div class="img_brian_tam bg-center bg-cover"></div>
    </div>

  </div>
</template>

<script>

import ButtonWithBg from "@/components/shared/button/ButtonWithBg";
// import ButtonOutline from "@/components/shared/button/ButtonOutline";
import resume from "../../../assets/documents/resume/Brian_Tam_Resume.pdf"
export default {
  name: "AboutMeSection",
  components: {
    ButtonWithBg,
    // ButtonOutline,
  },
  data(){
    return{
      resume: resume,
    }
  }
}
</script>

<style scoped>
.img_brian_tam{
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/img/about/myself.jpg");
}

.card{
  height: 200px;
}
</style>