import Vue from 'vue'
import App from './App.vue'
import './index.css'
import router from './router'
import i18n from './i18n'

import VueScrollTo from 'vue-scrollto';
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import VueMeta from 'vue-meta';

let scrollOptions = {
  container: "body", //滚动的容器
  duration: 500, //滚动时间
  easing: "ease", //缓动类型
  offset: 0, // 滚动时应应用的偏移量。此选项接受回调函数
  force: true, // 是否应执行滚动
  cancelable: true, // 用户是否可以取消滚动
  onStart: false, // 滚动开始时的钩子函数
  onDone: false, // 滚动结束时候的钩子函数
  onCancel: false, // 用户取消滚动的钩子函数
  x: false, // 是否要在x轴上也滚动
  y: true    // 是否要在y轴上滚动
};

Vue.use(VueScrollTo, scrollOptions);
Vue.use(VueMeta);
new Vue({
  router: router,
  i18n,
  render: h => h(App)
}).$mount('#app')
