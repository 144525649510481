<template>
  <div class="header w-full z-40 fixed top-0 flex justify-center ">
    <nav class="px-5 md:px-10 my-5 p-5 shadow-lg rounded flex bg-white">
      <a class="mr-10" href="#" v-scroll-to="home_scroll">Home</a>
      <a class="mr-10" href="#" v-scroll-to="about_me_scroll">About Me</a>
      <div class="static " @mouseleave="dropdown()">
        <a class="hover-pointer" aria-disabled="true" @click="dropdown()">Projects</a>
        <div v-if="is_project_drop_down" class="absolute top-35 ">
          <nav class="text-left rounded px-5 py-5 shadow-lg bg-white">
            <a class="mb-2 block" href="#" v-scroll-to="projects_android_scroll">Android</a>
            <a class="block" href="#" v-scroll-to="projects_web_scroll">Web</a>
          </nav>
        </div>
      </div>
    </nav>

    <!--      <router-link to="/">Home</router-link>-->
    <!--      <router-link to="/about_me">About</router-link>-->
  </div>
</template>

<script>
export default {
  name: "HeaderVue",
  data(){
    return{
      home_scroll : {
        el : '#home',
        offset : 0,
        cancelable : true
      },
      about_me_scroll : {
        el : '#about-me',
        offset : 0,
        cancelable : true
      },
      projects_scroll : {
        el : '#projects',
        offset : 0,
        cancelable : true
      },
      projects_android_scroll : {
        el : '#projects-android',
        offset : -20,
        cancelable : true
      },
      projects_web_scroll : {
        el : '#projects-web',
        offset : -20,
        cancelable : true
      },
      is_project_drop_down : false
    }
  },
  methods: {
    dropdown(){
      this.is_project_drop_down = !this.is_project_drop_down;
    }
  }
}
</script>

<style scoped>

</style>