<template>
  <div id="home" class="welcome-section w-screen h-full flex flex-wrap">
    <div class="w-full md:w-1/2 h-screen">
      <div class="left h-full flex flex-wrap flex-1 justify-center content-center bg-indigo-100 px-20 md:px-0">
        <div class="w-full">
          <p class="subpixel-antialiased font-medium tracking-widest leading-loose text-2xl text-blue-600">HEY! I AM</p>
        </div>
        <div class="w-full">
          <p class="subpixel-antialiased font-extrabold tracking-widest leading-loose text-4xl">Brian Tam</p>
        </div>
        <div class="w-full flex flex-wrap justify-center content-center">
          <p class="subpixel-antialiased font-semibold tracking-widest leading-loose text-2xl">I am</p>
          <Typewriter
              :replace="replace"
              :type-interval="100"
              :replace-interval="1200"
              class="subpixel-antialiased font-semibold tracking-widest leading-relaxed text-2xl text-blue-600 content-center"
          >
            <p class="ml-3 uppercase underline">{{ my_title }}</p>
          </Typewriter>
        </div>
      </div>

      <div class="triangle absolute bottom-16 left-1/2 hidden md:block">
        <div class="triangle-right">
        </div>
      </div>
      <div class="arrow absolute bottom-32 left-1/2 move-down-animation hidden md:block">
        <svg width="16" height="20" viewBox="0 0 16 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.29289 31.7071C7.68342 32.0976 8.31658 32.0976 8.70711 31.7071L15.0711 25.3431C15.4616 24.9526 15.4616 24.3195 15.0711 23.9289C14.6805 23.5384 14.0474 23.5384 13.6569 23.9289L8 29.5858L2.34315 23.9289C1.95262 23.5384 1.31946 23.5384 0.928932 23.9289C0.538408 24.3195 0.538408 24.9526 0.928932 25.3431L7.29289 31.7071ZM7 0L7 31H9L9 0L7 0Z" fill="#9A9A9A"/>
        </svg>
      </div>
    </div>

    <div class="right flex-1">
      <code-section></code-section>
    </div>




<!--    <about-me-section></about-me-section>-->
<!--    <programming-languages-icon-section></programming-languages-icon-section>-->
<!--    <project-main></project-main>-->
  </div>
</template>

<script>
import Typewriter from "typewriter-vue";
import CodeSection from "@/components/home/welcome/CodeSection";
// import AboutMeSection from "@/components/home/about_me/AboutMeSection";
// import ProgrammingLanguagesIconSection from "@/components/home/programming_languages_icon/ProgrammingLanguagesIconSection";
// import ProjectMain from "@/components/home/projects/ProjectMain";
export default {
  name: "WelcomeSection",
  components: {
    Typewriter,
    CodeSection,
    // AboutMeSection,
    // ProgrammingLanguagesIconSection,
    // ProjectMain
  },
  data() {
    return{
      my_title: "a Web Developer",
      replace: [
        {from: "a Web Developer" , to : "a Frontend Developer"},
        {from: "a Frontend Developer", to : "a Backend Developer"},
        {from: "a Backend Developer", to: "an Android Developer"},
        {from: "an Android Developer", to: "a Full-stack Developer"},
        {from: "a Full-stack Developer", to: "a Web Developer."}
      ]
    }
  }
}
</script>

<style scoped>
@keyframes changeBottom {
  from {
    bottom: 115px;
  }

  to {
    bottom: 95px;
  }
}
.triangle-right {
  width: 0;
  height: 0;
  border-top: 50px solid transparent;
  border-bottom: 50px solid transparent;
  border-left: 45px solid rgba(224, 231, 255, 100);
}

.move-down-animation{
  animation-duration: 1s;
  animation-name: changeBottom;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
</style>